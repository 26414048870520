<template>
  <div class="prodcost-products flex flex-column gap-4">
    <div class="flex items-center justify-between gap-2 w-100">
      <div class="flex gap-8">
        <date-range
          v-model="filters"
          @input="fetchRecords()"
        />
      </div>
    </div>

    <div class="font-bold">
      Showing {{ records.length | format }} / {{ recordCount | format }} record(s)
    </div>

    <table class="table table-google">
      <thead>
        <tr>
          <th>ID</th>
          <th>Created</th>
          <th>Product</th>
          <th class="text-nowrap">Fragrance %</th>
          <th class="text-nowrap">Unit Price (CZK)</th>
          <th class="text-nowrap">RRP (CZK)</th>
          <th class="text-nowrap">RRP (EUR)</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td colspan="4">
            <div class="font-bold">
              <span v-if="isLoading">Loading..</span>
              <span v-else-if="records.length === 0">No records found</span>
            </div>
          </td>
        </tr>

        <tr v-for="record in records" :key="record.id">
          <td class="row-fit">{{ record.id }}</td>
          <td class="row-fit">{{ record.create_date | isodate }}</td>
          <td class="text-nowrap">{{ record.sale_name }}</td>
          <td class="row-fit text-nowrap text-right">
            <span v-if="record.sale_pct_option_value">
              {{ record.sale_pct_option_value }}
            </span>
          </td>
          <td class="text-nowrap row-fit text-right font-mono">
            {{ roundNumber(record.sale_price, 2, 2) }}
          </td>
          <td class="text-nowrap row-fit text-right font-mono">
            {{ roundNumber(record.rrp_price_czk, 2, 2) }}
          </td>
          <td class="text-nowrap row-fit text-right font-mono">
            {{ roundNumber(record.rrp_price_eur, 2, 2) }}
          </td>
          <td class="row-fit">
            <div class="flex gap-2">
             <b-button
                variant="primary"
                size="sm"
                @click="editRecord(record)"
              >
                Edit
              </b-button>

              <b-button
                variant="primary"
                size="sm"
                @click="duplicateRecord(record)"
              >
                Duplicate
              </b-button>

              <b-button
                variant="danger"
                size="sm"
                @click="deleteRecord(record)"
              >
                Delete
              </b-button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <b-pagination
      v-model="filters.page"
      :total-rows="recordCount"
      :per-page="filters.per_page"
      @input="fetchRecords(true)"
      :disabled="isLoading"
    ></b-pagination>
  </div>
</template>

<script>
import { roundNumber } from '@/helpers';

const DateRange = () => import('@/components/invoices/DateRange.vue');

export default {
  name: 'Products',
  components: {
    DateRange,
  },
  computed: {
    isLoading() {
      return this.loadingCount > 0;
    },
  },
  data() {
    return {
      loadingCount: 0,
      records: [],
      recordCount: 0,
      filters: {
        date_from: null,
        date_to: null,
        page: 1,
        per_page: 50,
      },
      options: {},
    };
  },
  methods: {
    fetchRecordData() {
      this.loadingCount++;
      this.$http
        .get('/prodcost')
        .query(this.filters)
        .then((res) => {
          this.records = res.body.records;
        })
        .catch((err) => {
          this.$toast.error(`Failed to fetch records: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    fetchRecordCount() {
      this.loadingCount++;
      this.$http
        .get('/prodcost_count')
        .query(this.filters)
        .then((res) => {
          this.recordCount = res.body.count;
        })
        .catch((err) => {
          this.$toast.error(`Failed to fetch record count: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    fetchRecords(paginate) {
      paginate = paginate === undefined ? false : paginate;
      if (paginate === false) {
        this.fetchRecordCount();
        this.filters.page = 1;
      } else {
        setTimeout(() => {
          window.scrollTo(0, 0);
        }, 50);
      }
      this.fetchRecordData();
    },
    editRecord(record) {
      this.$emit('load', {
        id: record.id,
        type: 'edit',
      });
    },
    duplicateRecord(record) {
      this.$emit('load', {
        id: record.id,
        type: 'duplicate',
      });
    },
    deleteRecord(deleteRecord) {
      if (!confirm('Do you really wish to delete this record?')) {
        return;
      }
      this.loadingCount++;
      this.$http
        .delete(`/prodcost/${deleteRecord.id}`)
        .then(() => {
          this.records = this.records.filter((record) => record.id !== deleteRecord.id);
          this.recordCount--;
        })
        .catch((err) => {
          this.$toast.error(`Failed to delete record: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    roundNumber(value, minimumFractionDigits, maximumFractionDigits) {
      return roundNumber(value, minimumFractionDigits, maximumFractionDigits);
    },
  },
};
</script>

<style lang="scss" scoped>
  .prodcost-products {
    max-width: 1000px;
  }
</style>
